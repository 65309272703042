import type { PredefinedCardFrameValue } from 'model/indexTS';
import mediaOptimizer from 'utils/media-optimizer';
import staticImages from 'constants/aws';
import { ForceCloudinaryInfo } from 'utils/media-optimizer/MediaOptimizer';

const XSMALL = '(max-width: 360px)';
const xsmallQuery = typeof matchMedia === 'undefined' ? null : matchMedia(XSMALL);

const SMALL = '(max-width: 620px)';
const smallQuery = typeof matchMedia === 'undefined' ? null : matchMedia(SMALL);

const LARGE = '(max-width: 1200px)';
const largeQuery = typeof matchMedia === 'undefined' ? null : matchMedia(LARGE);

function getCardWidthForResolution(): number {
  if (!xsmallQuery || !largeQuery || !smallQuery) {
    return 240;
  }

  if (xsmallQuery.matches) {
    return 150;
  }

  if (smallQuery.matches) {
    return 180;
  }

  if (largeQuery.matches) {
    return 200;
  }

  return 240;
}

export type GetCardImageUrlParams =
  | string
  | {
      imageUrl?: string;
      frames?: PredefinedCardFrameValue[];
    };

function getCardImageUrl(
  card: GetCardImageUrlParams,
  frameId?: string | null,
  width?: number,
  forceCloudinary?: ForceCloudinaryInfo,
): string {
  const cardWidth = width || getCardWidthForResolution();

  if (typeof card === 'string') {
    return mediaOptimizer.optimizeImage(card, { width: cardWidth }, forceCloudinary);
  }

  const { imageUrl, frames } = card;

  if (imageUrl) {
    return mediaOptimizer.optimizeImage(imageUrl, { width: cardWidth }, forceCloudinary);
  }

  if (frameId && Array.isArray(frames) && frames.length > 0) {
    const frame = frames.find(frameElement => frameElement.frameId === frameId) || frames[0];
    return mediaOptimizer.optimizeImage(frame.imageUrl, { width: cardWidth }, forceCloudinary);
  }

  return staticImages.CARDS.PLACEHOLDER;
}

export default {
  getCardImageUrl,
  getCardWidthForResolution,
};
